import React, { useContext } from 'react';
import { GameContext, GameProvider } from './components/GameContext';
import { LanguageProvider, useLanguage } from './components/LanguageContext';
import { FaArrowLeft } from 'react-icons/fa';
import Logo from './components/Logo';

import CreateRoom from './components/CreateRoom';
import JoinRoom from './components/JoinRoom';
import WaitingRoom from './components/WaitingRoom';
import GameScreen from './components/GameScreen';
import './App.css';


const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();
  return (
      <button className="top-right-flag-button" onClick={toggleLanguage}>
                    <span className="emoji">{language === 'en' ? '🇺🇸' : '🇰🇷'}</span>
      </button>
  );
};

const App = () => {
    const { step, setStep } = useContext(GameContext);
    const { language, toggleLanguage } = useLanguage();

    const handleBackClick = () => {
        setStep(1); // Go back to the initial screen
    };

    const handleCreateRoomClick = () => {
        setStep(4); // Custom step for CreateRoom screen
    };

    const handleJoinRoomClick = () => {
        setStep(5); // Custom step for JoinRoom screen
    };

    return (
        <div className="App">
            {step !== 1 && step !== 3 && (
                <button className="top-left" onClick={handleBackClick}>
                    <FaArrowLeft />
                </button>
            )}
            {step === 1 && <LanguageToggle />}
            {step === 1 && (
                <div className="initial-screen">
                     <div className="page-section">
                    <Logo size="large" />
                    </div>  
                    <button onClick={handleCreateRoomClick}>
                        {language === 'en' ? 'Create Room' : '방 만들기'}
                    </button>
                    <button onClick={handleJoinRoomClick}>
                        {language === 'en' ? 'Join Room' : '방 참여'}
                    </button>

                </div>
            )}
            {step === 4 && <CreateRoom />}
            {step === 5 && <JoinRoom />}
            {step === 2 && <WaitingRoom />}
            {step === 3 && <GameScreen />}
        </div>
    );
};

const AppWrapper = () => (
    <LanguageProvider>
        <GameProvider>
            <App />
        </GameProvider>
    </LanguageProvider>
);

export default AppWrapper;
