import React, { useContext, useEffect, useState } from 'react';
import { GameContext } from './GameContext';
import { FaInfinity } from 'react-icons/fa';
import Whiteboard from './Whiteboard';
import Whiteboard2 from './Whiteboard2';
import './GameScreen.css';
import Logo from './Logo';
import { ref, set, onValue } from 'firebase/database';
import { database } from '../firebase';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import { RiRestartLine } from "react-icons/ri";

const RankingPopup = ({ scores, onReplay, onFirstTime, isHost }) => {
  const sortedScores = Object.entries(scores).sort((a, b) => b[1] - a[1]);

  return (
    <div className='ranking-bg'>
    <div className="ranking-popup">
      <h2 style={{ textAlign: 'center' }}>Ranking</h2>
      <div className="ranking-list">
        {sortedScores.map(([name, score], index) => (
          <div key={name} className="ranking-item">
            {index + 1}. {name} - {score} p
          </div>
        ))}
      </div>
      {isHost && (
        <div className="ranking-actions">
          <button onClick={onReplay}><RiRestartLine />          </button>
          <button onClick={onFirstTime}>Home </button>
        </div>
      )}
    </div>
    </div>
  );
};

const PlayerListForDrawer = ({ players, currentDrawer, scores, handleMarkCorrect }) => (
  <div className="players-list drawer-list">
    {players.map(player => (
      <div 
        key={player.id}
        className={`player ${currentDrawer === player.id ? 'drawing-player' : ''}`}
      >
        {currentDrawer !== player.id && (
          <span className='name-side-btn' onClick={() => handleMarkCorrect(player.id)}>
            ⭐
          </span>
        )}
        {currentDrawer === player.id && <span className="name-side-btn">🖍️</span>} {player.id}<br />{player.name} <br /><span className="player-score">{scores[player.name] || 0}p</span>
        <span className={currentDrawer === player.id ? 'drawer-name' : ''}></span>
      </div>
    ))}
  </div>
);

const PlayerListForNonDrawer = ({ players, currentDrawer, scores }) => (
  <div className="players-list non-drawer-list">
    {players.map(player => (
      <div 
        key={player.id}
        className={`player ${currentDrawer === player.id ? 'drawing-player' : ''}`}
      >
        {currentDrawer === player.id && <span className="name-side-btn">🖍️</span>} {player.id}<br />{player.name} <br /><span className="player-score">{scores[player.name] || 0}p</span>
        <span className={currentDrawer === player.id ? 'drawer-name' : ''}></span>
      </div>
    ))}
  </div>
);

const GameScreen = () => {
  const { 
    gameSettings, 
    players, 
    currentDrawer, 
    scores, 
    currentKeyword, 
    setCurrentKeyword, 
    isDrawing, 
    setIsDrawing, 
    role, 
    roomCode, 
    endGame,
    handleKeywordInput,
    checkAnswer,
    isTypingKeyword,
    setIsTypingKeyword,
    correctGuess,
    showCorrectPopup,
    setShowCorrectPopup,
    showRankingPopup,
    setShowRankingPopup,
    hostName,
    guestName,
    markCorrectAnswer,
    updateScore,
    nextDrawer,
    playerID,
    setPlayers,
    setScores,
    setStep,
    setCorrectGuess // Add this line to destructure setCorrectGuess from context
  } = useContext(GameContext);
  const [timeLeft, setTimeLeft] = useState(gameSettings.timeLimit);
  const [answer, setAnswer] = useState('');
  const [keywordEntered, setKeywordEntered] = useState(false);
  const { language } = useLanguage(); // Use the useLanguage hook

  useEffect(() => {
    if (gameSettings.timeLimit > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            nextDrawer();
            return gameSettings.timeLimit;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [gameSettings.timeLimit, nextDrawer]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        checkAnswer(answer, playerID);
        setAnswer('');
      } else {
        setAnswer(prev => prev + e.key);
      }
    };

    if (gameSettings.keywords && playerID !== currentDrawer) {
      window.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (gameSettings.keywords && playerID !== currentDrawer) {
        window.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [answer, checkAnswer, playerID, gameSettings.keywords, currentDrawer]);

  const handleKeywordChange = (e) => {
    setCurrentKeyword(e.target.value);
    setIsTypingKeyword(true);
  };

  const handleKeywordSubmit = () => {
    handleKeywordInput(currentKeyword);
    setKeywordEntered(true);
    setIsTypingKeyword(false);
  };

  useEffect(() => {
    if (playerID === currentDrawer) {
      setKeywordEntered(false);
    }
  }, [currentDrawer, playerID]);

  const handleMarkCorrect = (playerID) => {
    console.log(`Marking player with ID: ${playerID} as correct.`);
    markCorrectAnswer(playerID);
  };
    
  const handleEndGame = () => {
    setShowRankingPopup(true);
    set(ref(database, `rooms/${roomCode}/showRankingPopup`), true);
  };

  const handleReplay = () => {
    // Reset scores and set the first drawer
    const updatedScores = {};
    players.forEach(player => {
      updatedScores[player.name] = 0;
    });
    setScores(updatedScores); // Use the correct context function to update scores
    set(ref(database, `rooms/${roomCode}/scores`), updatedScores);
    set(ref(database, `rooms/${roomCode}/currentDrawer`), players[0].id); // Ensure first drawer is set
    setShowRankingPopup(false);
    set(ref(database, `rooms/${roomCode}/showRankingPopup`), false);
    set(ref(database, `rooms/${roomCode}/showReplayPopup`), true);
    setTimeout(() => {
      set(ref(database, `rooms/${roomCode}/showReplayPopup`), false);
    }, 2000);
  };

  const handleFirstTime = () => {
    setStep(1);
    const emptyPlayers = [];
    const emptyScores = {};
    setPlayers(emptyPlayers); // Use the correct context function to update players
    setScores(emptyScores); // Use the correct context function to update scores
    set(ref(database, `rooms/${roomCode}/players`), emptyPlayers);
    set(ref(database, `rooms/${roomCode}/scores`), emptyScores);
    set(ref(database, `rooms/${roomCode}/gameStep`), 1); // Set the game step to 1 in Firebase
    set(ref(database, `rooms/${roomCode}/showEndGamePopup`), true);
    setTimeout(() => {
      set(ref(database, `rooms/${roomCode}/showEndGamePopup`), false);
    }, 2000);
  };

  useEffect(() => {
    const showCorrectPopupRef = ref(database, `rooms/${roomCode}/showCorrectPopup`);
    onValue(showCorrectPopupRef, (snapshot) => {
      const data = snapshot.val();
      setShowCorrectPopup(data);
    });

    const showRankingPopupRef = ref(database, `rooms/${roomCode}/showRankingPopup`);
    onValue(showRankingPopupRef, (snapshot) => {
      const data = snapshot.val();
      setShowRankingPopup(data);
    });

    const gameStepRef = ref(database, `rooms/${roomCode}/gameStep`);
    onValue(gameStepRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setStep(data);
      }
    });

    const correctGuessRef = ref(database, `rooms/${roomCode}/correctGuess`);
    onValue(correctGuessRef, (snapshot) => {
      const data = snapshot.val();
      setCorrectGuess(data);
    });
  }, [roomCode]);

  useEffect(() => {
    console.log("showCorrectPopup:", showCorrectPopup);
    console.log("showRankingPopup:", showRankingPopup);
  }, [showCorrectPopup, showRankingPopup]);

  return (
    <div className="game-screen">
      <Logo size="medium" />
      {role === 'host' && (
        <div className="end-game">
          <button onClick={handleEndGame}>End</button>
        </div>
      )}

      <div className="time-bar">
        {gameSettings.timeLimit === 0 ? <FaInfinity /> : timeLeft}
      </div>

      {playerID === currentDrawer ? (
        <PlayerListForDrawer 
          players={players} 
          currentDrawer={currentDrawer} 
          scores={scores} 
          handleMarkCorrect={handleMarkCorrect}
        />
      ) : (
        <PlayerListForNonDrawer 
          players={players} 
          currentDrawer={currentDrawer} 
          scores={scores}
        />
      )}

      <Whiteboard2 />

      {showCorrectPopup && (
        <div className="correct-popup">
          {language === 'en' ? `Correct keyword guessed by ${correctGuess}!` : `${correctGuess}님 정답!`}
        </div>
      )}

      {showRankingPopup && (
        <RankingPopup 
          scores={scores} 
          onReplay={handleReplay} 
          onFirstTime={handleFirstTime} 
          isHost={role === 'host'}
        />
      )}

      <div className='gamescreen-info'></div>
    </div>
  );
};

export default GameScreen;
