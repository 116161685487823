import React, { useState, useContext, useEffect } from 'react';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook

const JoinRoom = () => {
  const { joinRoom, setGuestName, errorMessage, setErrorMessage } = useContext(GameContext);
  const { language } = useLanguage(); // Use the useLanguage hook
  const [nickname, setNickname] = useState('');
  const [roomCode, setRoomCode] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);

    } else {
      setShowPopup(false);
    }
  }, [errorMessage]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setRoomCode(value);
    }
  };

  const handleJoinRoom = () => {
    if (!nickname) {
      setErrorMessage(language === 'en' ? 'Please enter a nickname' : '닉네임을 입력하세요');
    } else if (!roomCode) {
      setErrorMessage(language === 'en' ? 'Please enter a room code' : '방 번호를 입력하세요');
    } else {
      setErrorMessage(''); // Reset any previous error message
      setGuestName(nickname);
      joinRoom(roomCode, nickname);
    }
  };

  return (
    <div className="create-room">
      <h1>{language === 'en' ? 'Join Room' : '방 참여하기'}</h1>
      <div className="code-input">
        <input
          type="text"
          placeholder={language === 'en' ? 'Enter room code' : '방 번호를 입력하세요'}
          value={roomCode}
          onChange={handleChange}
          maxLength="8"
          pattern="\d*"
          className="hidden-input"
        />
        <div className="input-boxes">
          {roomCode.split('').map((digit, index) => (
            <span key={index} className="digit-box">{digit}</span>
          ))}
          {[...Array(8 - roomCode.length)].map((_, index) => (
            <span key={index + roomCode.length} className="digit-box placeholder-box">⚪</span>
          ))}
        </div>
      </div>
      <input 
        type="text" 
        placeholder={language === 'en' ? 'Enter your nickname' : '닉네임을 입력하세요'}
        value={nickname} 
        onChange={(e) => setNickname(e.target.value)} 
      />
      <button onClick={handleJoinRoom}>{language === 'en' ? 'Join Room' : '입장!'}</button>
      {showPopup && (
        <div className="popup_error">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default JoinRoom;
