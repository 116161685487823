import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import { GameContext } from './GameContext';
import { database } from '../firebase';
import { ref, onValue, push, set } from 'firebase/database';
import { GrClearOption } from "react-icons/gr";
import throttle from 'lodash/throttle';

const Whiteboard2 = ({ throttleValue = 100}) => {
  const canvasRef = useRef(null);
  const { roomCode, currentDrawer, playerID, isDrawing, setIsDrawing } = useContext(GameContext);
  const isDrawer = playerID === currentDrawer;
  const [tool, setTool] = useState('pen');
  const [penSize, setPenSize] = useState(5);
  const [eraserSize, setEraserSize] = useState(10);
  const drawingQueue = useRef([]);
  const compressedQueue = useRef([]);

  const getOffset = (e, rect, canvas) => {
    const offsetX = (e.clientX || e.touches[0].clientX) - rect.left;
    const offsetY = (e.clientY || e.touches[0].clientY) - rect.top;
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    return { x: offsetX * scaleX, y: offsetY * scaleY };
  };

  const throttledHandleMove = useCallback(throttle((e, ctx, canvas, drawing, tool, penSize, eraserSize) => {
    if (!isDrawer || !drawing.current) return;
    const rect = canvas.getBoundingClientRect();
    const { x, y } = getOffset(e, rect, canvas);
    if (tool === 'pen') {
      ctx.lineWidth = penSize;
      ctx.strokeStyle = 'black';
    } else if (tool === 'eraser') {
      ctx.lineWidth = eraserSize;
      ctx.strokeStyle = 'white';
    }
    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y);
    drawingQueue.current.push({ type: 'draw', x, y, tool, size: tool === 'pen' ? penSize : eraserSize });
    compressedQueue.current.push([x, y, tool === 'pen' ? 0 : 1, tool === 'pen' ? penSize : eraserSize]);
  }, throttleValue), [isDrawer, tool, penSize, eraserSize, throttleValue]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    let drawing = { current: false };

    const handleStart = (e) => {
      if (!isDrawer) return;
      drawing.current = true;
      const rect = canvas.getBoundingClientRect();
      const { x, y } = getOffset(e, rect, canvas);
      ctx.beginPath();
      ctx.moveTo(x, y);
      drawingQueue.current.push({ type: 'begin', x, y, tool, size: tool === 'pen' ? penSize : eraserSize });
      compressedQueue.current.push([x, y, tool === 'pen' ? 0 : 1, tool === 'pen' ? penSize : eraserSize, 'begin']);
    };

    const handleEnd = () => {
      if (!isDrawer) return;
      drawing.current = false;
      ctx.closePath();
      sendBatchToDatabase();
    };

    const sendBatchToDatabase = () => {
      if (compressedQueue.current.length > 0) {
        const batch = compressedQueue.current;
        compressedQueue.current = [];
        push(ref(database, `rooms/${roomCode}/draw`), batch);
      }
    };

    const drawFromFirebase = (data) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      data.forEach(([x, y, tool, size, type]) => {
        if (type === 'begin') {
          ctx.beginPath();
          ctx.moveTo(x, y);
        } else {
          ctx.lineWidth = size;
          ctx.strokeStyle = tool === 0 ? 'black' : 'white';
          ctx.lineTo(x, y);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(x, y);
        }
      });
    };

    const handleMove = (e) => {
      throttledHandleMove(e, ctx, canvas, drawing, tool, penSize, eraserSize);
    };

    canvas.addEventListener('mousedown', handleStart);
    canvas.addEventListener('mouseup', handleEnd);
    canvas.addEventListener('mousemove', handleMove);
    canvas.addEventListener('touchstart', handleStart);
    canvas.addEventListener('touchend', handleEnd);
    canvas.addEventListener('touchmove', handleMove);

    const drawRef = ref(database, `rooms/${roomCode}/draw`);
    onValue(drawRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const coordinates = Object.values(data).flat();
        drawFromFirebase(coordinates);
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    });

    return () => {
      canvas.removeEventListener('mousedown', handleStart);
      canvas.removeEventListener('mouseup', handleEnd);
      canvas.removeEventListener('mousemove', handleMove);
      canvas.removeEventListener('touchstart', handleStart);
      canvas.removeEventListener('touchend', handleEnd);
      canvas.removeEventListener('touchmove', handleMove);
    };
  }, [roomCode, isDrawer, tool, penSize, eraserSize, throttledHandleMove]);

  useEffect(() => {
    if (isDrawer) {
      clearCanvas();
    }
  }, [currentDrawer, isDrawer]);

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    set(ref(database, `rooms/${roomCode}/draw`), null);
  };

  const drawerStyle = {
    backgroundColor: 'transparent',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 10px 20px rgba(255, 152, 0, 0.5)',
  };

  return (
    <div className='drawing-window'>
      <div style={isDrawer ? drawerStyle : {}}>
        {isDrawer && (
          <div 
            style={{ 
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
              alignItems: 'center',
              gap: '10px',
              height: '50px' 
            }}
          >
            <span
              onClick={() => { setTool('pen'); setPenSize(5); }}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '100%',
                backgroundColor: 'black',
                cursor: 'pointer',
              }}
            />
            <span
              onClick={() => { setTool('pen'); setPenSize(10); }}
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: 'black',
                cursor: 'pointer'
              }}
            />
            <span
              onClick={() => { setTool('pen'); setPenSize(15); }}
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: 'black',
                cursor: 'pointer'
              }}
            />
            <span
              onClick={() => { setTool('eraser'); setEraserSize(10); }}
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: 'white',
                border: '1px solid black',
                cursor: 'pointer'
              }}
            />
            <div
              onClick={() => { setTool('eraser'); setEraserSize(20); }}
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: 'white',
                border: '1px solid black',
                cursor: 'pointer'
              }}
            />
            <span
              onClick={() => { setTool('eraser'); setEraserSize(30); }}
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: 'white',
                border: '1px solid black',
                cursor: 'pointer'
              }}
            />
            <button 
              onClick={clearCanvas}
              style={{
                color: 'white',
                backgroundColor: 'red',
                border: 'none',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                padding: '5px 5px',
                cursor: 'pointer',
              }}
            ><GrClearOption /></button>
          </div>
        )}
        <canvas
          ref={canvasRef}
          width={400}
          height={400}
          style={{
            border: '1px solid black',
            backgroundColor: 'white',
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    </div>
  );
};

export default Whiteboard2;
